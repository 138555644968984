<template>
    <div class="container p-2 p-md-2">
        <div class="row g-3">
            <div class="col-12 col-md-6">

                <h2>Personal Bests</h2>
                <div>
                    <label for="inputMarathon" class="form-label">Marathon</label>
                    <input type="text" class="form-control" id="inputMarathon" placeholder="02:59:31">
                </div>
                <div >
                    <label for="inputHalfMarathon" class="form-label">Half marathon</label>
                    <input type="text" class="form-control" id="inputHalfMarathon" placeholder="01:29:10">
            </div>
            <div >
                <label for="input10k" class="form-label">10 km</label>
                <input type="text" class="form-control" id="input10k" placeholder="00:39:10">
            </div>
            <div >
                <label for="input5k" class="form-label">5 km</label>
                <input type="text" class="form-control" id="input5k" placeholder="00:19:10">
            </div>
        </div>
        <div class="col-md-6 col-12">

            <h2>Goals</h2>
            <div >
                <label for="inputState" class="form-label">Event</label>
                <select id="inputState" class="form-select">
                    <option selected>Marathon</option>
                    <option>Half marathon</option>
                    <option>10 k</option>
                    <option>5 k</option>
                </select>
            </div>
                <button type="submit" class=" w-100 btn btn-sm mt-2 btn-primary">Save</button>
            
        </div>
    </div>
    </div>
</template>

<script>
// import axios from 'axios'
export default {
    name: 'UserData',
    mounted() {

    },
    props: {

    },
    data() {
    }
}
</script>
<style scoped>

</style>