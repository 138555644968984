<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-bottom d-md-none bg-primary vw-100">
        <div class="container-fluid px-3 py-1 d-flex justify-content-end">
           
            <!-- <router-link to="#features" class="btn btn-outline-light p-2 rounded-pill nav-item nav-link me-lg-3 bg-white" href="#features">Features
            </router-link>
            <router-link to="#buy" class="btn btn-outline-light p-2 rounded-pill nav-item nav-link me-lg-3 bg-white" href="#features">Buy Urris
            </router-link> -->
            <div class="signin">
                <a
                    v-bind:href="'https://www.strava.com/oauth/authorize?client_id=23542&redirect_uri=' + origin + '%2Fapi%2Fcallbackauthstrava&approval_prompt=auto&response_type=code&scope=read,activity:read,read_all,activity:read_all'"><img
                        src="btn_strava_connectwith_orange.svg" /></a>
            </div>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top d-none d-md-block bg-primary">
        <div class="container-fluid px-5 justify-content-end">
            <router-link to="#features" class=" nav-item nav-link me-lg-auto d-none" id="nav-urris" href="#"><h2>Urris</h2>
            </router-link>
<!--             
            <router-link to="#features" class="btn btn-outline-light p-2 rounded-pill nav-item nav-link me-lg-3  bg-white" href="#features">Features
            </router-link>
            <router-link to="#buy" class=" btn btn-outline-light p-2 rounded-pill nav-item nav-link me-lg-3  bg-white" href="#features">Buy Urris
            </router-link> -->
            <div class="signin">
                <a
                    v-bind:href="'https://www.strava.com/oauth/authorize?client_id=23542&redirect_uri=' + origin + '%2Fapi%2Fcallbackauthstrava&approval_prompt=auto&response_type=code&scope=read,activity:read,read_all,activity:read_all'"><img
                        src="btn_strava_connectwith_orange.svg" /></a>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'HeaderPublic',
    data() {

        return {
            origin: location.origin.includes("localhost") ? "http://localhost:8000" : location.origin

        }
    }
}
</script>