// function formatTime(seconds) {
//     let hours = Math.floor(seconds / 3600);
//     let minutes = Math.floor((seconds % 3600) / 60);
//     let remainingSeconds = seconds % 60;
//     let formattedTime = '';

//     if (hours > 0) {
//         formattedTime = hours + ':' + pad(minutes) + ':' + pad(remainingSeconds);
//     } else {
//         formattedTime = minutes + ':' + pad(remainingSeconds);
//     }

//     return formattedTime;
// }

function pad(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

export default {
    install: (app) => {
        app.config.globalProperties.$formatTime = (seconds) => {
            let hours = Math.floor(seconds / 3600);
            let minutes = Math.floor((seconds % 3600) / 60);
            let remainingSeconds = seconds % 60;
            let formattedTime = '';

            if (hours > 0) {
                formattedTime = hours + ':' + pad(minutes) + ':' + pad((Math.round(remainingSeconds * 10) / 10).toFixed());
            } else {
                formattedTime = minutes + ':' + pad((Math.round(remainingSeconds * 10) / 10).toFixed(1)) +' min';
            }

            return formattedTime;
        }

    }
}
