<template>
  <router-link :to="'/user/activity/' + activity.id">
    <div class="card">
      <div class="card-body p-2">
        <h6 class="card-title">{{ activity.name }} | {{ $formatDate(activity.date) }}</h6>
        <h6 class="card-subtitle mb-2 text-muted"> {{ activity.pace }}/{{ unit }} | {{ activity.distance.toFixed(2) }} {{ unit }}</h6>

        <p class="card-text">

        </p>
      </div>
    </div>
  </router-link>
</template>

<script>


export default {
  name: 'ActivityCard',
  components: {
  },
  props: {
    activity: {}
  },
  data() {
    return { unit: 'km' }
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: unset;
    text-decoration: none;
}
</style>