import { createApp } from 'vue'
import { createWebHistory, createRouter } from "vue-router";
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import LandingPage from './components/LandingPage';
import AthleteDashboard from './components/AthleteDashboard';
import ActivityDetails from './components/ActivityDetails';
import NewUser from './components/NewUser';
import UserData from './components/UserData';
import WeeklyWorkouts from './components/WeeklyWorkouts.vue';
import { createPinia } from 'pinia' // Import
import VueCookies from 'vue3-cookies'
import { useAuthenticatedStore } from '@/stores/authenticated'
import { importFontAwesomeIcons } from './importedIcon';
import formatTime from './plugins/format-time-plugin';
import secondsToMinutesPerKm from './plugins/format-seconds-to-min-per-k';
import formatMpsToMpk from './plugins/format-mps-to-mpk-plugin';
import formatDate from './plugins/format-date-plugin';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'

axios.defaults.baseURL = process.env.BASE_URL || 
    (typeof window !== 'undefined' && window.location.origin.includes("localhost") 
        ? "http://localhost:8000" 
        : "https://urris.onrender.com");
importFontAwesomeIcons();

const routes = [
  { path: '/', component: LandingPage },
  {
    path: '', component: LandingPage,
    meta: {
      publicRoute: true,
      title: 'Urris - Runningcoach',
      metaTags: [
        {
          name: 'description',
          content: 'Urris, a smart running coach that makes running more fun and fullfills your potential'
        },
        {
          property: 'og:description',
          content: 'Urris, a smart running coach that makes running more fun and fullfills your potential'
        },
        {
          property: 'og:image',
          content: '@/avatar-face.png"'
        }
      ]
    }
  },
  { path: '/user', component: AthleteDashboard,
  meta: {
    publicRoute: true,
    title: 'Urris - Runningcoach',
    metaTags: [
      {
        name: 'description',
        content: 'Urris, a smart running coach that makes running more fun and fullfills your potential'
      },
      {
        property: 'og:description',
        content: 'Urris, a smart running coach that makes running more fun and fullfills your potential'
      },
      {
        property: 'og:image',
        content: '@/avatar-face.png"'
      }
    ]
  } 
},
  {
    path: '/user/info',
    component: UserData,
  },
  {
    path: '/newuser',
    component: NewUser,
  },
  {
    path: '/user/activity/:id',
    component: ActivityDetails,
  },
  {
    path: '/weekly-workouts',
    component: WeeklyWorkouts,
  }
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});
const vueApp = createApp(App);
vueApp.component('font-awesome-icon', FontAwesomeIcon);
vueApp.use(VueCookies);
vueApp.use(formatTime);
vueApp.use(formatMpsToMpk);
vueApp.use(secondsToMinutesPerKm);
vueApp.use(formatDate);
vueApp.use(createPinia());
vueApp.use(router);

const authenticatedStore = useAuthenticatedStore(); 
router.beforeEach((to, from, next) => {
  // Set the document title
  document.title = "Urris Running Coach";

  const { $cookies } = vueApp.config.globalProperties;
  const isAuthenticated = $cookies.get('authenticated') === 'true';
  const loggedIn = authenticatedStore.getLoggedIn();

  // Redirect unauthenticated users to the root if they attempt to access protected routes
  if (!isAuthenticated && to.path !== '/' && to.path !== '') {
    authenticatedStore.authenticated = false;
    next('/');
    return;
  } 

  // Synchronize the store with the cookie if authenticated but not reflected in the store
  if (isAuthenticated && !loggedIn) {
    authenticatedStore.authenticated = true;
    
    // Only redirect to /user if the user is trying to access the root path
    if (to.path === '/' || to.path === '' || to.path === '/user') {
      next('/user');
    } else {
      next(); 
    }
    return;
  }

  // Ensure store reflects logged out status if cookies indicate unauthenticated
  if (!isAuthenticated) {
    authenticatedStore.authenticated = false;
  }

  // Allow navigation for all other cases
  next();
});


vueApp.mount('#app');
