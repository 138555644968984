<template>
    <div class="card">
      <div class="card-header">
        <h5>Personal Records</h5>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">5 km: {{ pr5km }}</li>
        <li class="list-group-item">10 km: {{ pr10km }}</li>
        <li class="list-group-item">Half Marathon: {{ prHalfMarathon }}</li>
        <li class="list-group-item">Marathon: {{ prMarathon }}</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AthletePrs',
    props: ['pr5km', 'pr10km', 'prHalfMarathon', 'prMarathon'],
  };
  </script>