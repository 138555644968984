import { defineStore } from 'pinia'

export const useAuthenticatedStore = defineStore({
  id: 'authenticated',
  state: () => ({
    authenticated: null,
    accesstoken: null,
    loading: false,
    error: null
  }),
  getters: {
    getLoggedIn: (state) => {
      return () => state.authenticated
    }
  }, 
  actions: {
    
  }
})