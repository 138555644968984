<template>
  <div>
    <!-- Navigation-->

    <!-- Mashead header-->
    <div class="py-1">
      <div class="container px-md-5">
        <div class="gx-5 align-items-center">
          <div class="col-12 d-flex flex-column" style="height: 50vh; position: relative;" >
            <transition name="fade">

              <video v-if="!showImage" class="img-fluid h-100 fade-in-image" autoplay muted playsinline @ended="onVideoEnd">
                <source src="run_til_you_die.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </transition>
            <transition name="fade">
              <UrrisComment class="fade-in-image" style="position: relative; flex-direction: column; align-items: start;" v-if="showImage" :comment="urrisMessage">

              </UrrisComment>
            </transition>
          </div>
          <div class="col-12 d-flex justify-content between">
            <!-- Mashead text and app badges-->
            <div class="mb-5 mb-lg-0 text-center text-lg-start  m-auto">
              <h1 class="display-1 lh-1 mb-3 display-1 lh-1 mb-3 ">Urris</h1>
              <p class="lead fw-normal text-muted mb-5 text-start" id="sticky-brand-indicator">Running influencers are long gone<br> Long live Urris<br> A real (webbased) running coach
                <br> 
                <!-- <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="text-info" role="button">Who is Urris and how can he improve my
                  running?</a> -->
                </p>

              <!-- Modal -->
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                
                <!-- <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">Two faced running coach</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="accordion" id="accordionPanelsStayOpenExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseOne">
                              <strong> Urris, the story - </strong>The best of two worlds
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingOne">
                            <div class="accordion-body text-start">

                              Urris was once a rising star of running in sweden. He had always been a natural athlete,
                              and as a teenager, he began
                              to focus his energy on becoming a professional runner. He trained tirelessly, pushing
                              himself to the limit and beyond.
                              <p>
                                In his early career, Urris reached the pinnacle of his sport, winning numerous national
                                and international races,
                                setting new records and becoming one of the elite runners in europe.
                              </p>
                              <p>
                                But as much as he loved running, Urris had always been interested in statistics. He had
                                been fascinated by data analysis
                                since he was a child, and as he grew older, that fascination only deepened. Eventually,
                                Urris realized that he wanted to
                                pursue a career in the field, and so, despite his great success in running, he made the
                                decision to retire from
                                professional athletics and go back to school to study statistics.
                              </p>
                              <p>
                                After completing his education, Urris got a job as a statistician in the insurance
                                industry. He threw himself into his
                                work, and soon, he was making important contributions to the field. But he never stopped
                                running, he continued to train
                                and soon he found that he was running just as well as he ever had before.
                              </p>
                              <p>
                                Then, one day he decided to enter a local marathon, he found that he was still one of
                                the top runners in the country and
                                even won the race. His dedication, hard work, and statistical approach made him an
                                inspiration for many amateur runners,
                                and many started to seek his advice and training methods.
                                Urris was now a successful statistician in the insurance industry and an elite runner,
                                proving that it is possible to
                                excel in multiple areas. He showed them that, with hard work and dedication, anything is
                                possible. He continued to
                                inspire people who were looking to balance multiple passions and careers. Urris was the
                                living example of how you can
                                achieve great things, not by choosing one or the other, but by balancing both.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo">
                              Face 1 - Hard work and fun work
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo">
                            <div class="accordion-body text-start">
                              <p>
                                So the first face of Urris. A former elite runner with a wealth of experience in the
                                world of professional athletics.
                                With a proven track record of success, Urris can help you achieve your running goals
                                through workout suggestions based
                                on your training.
                              </p>
                              <p>
                                He will give you encouragements, workoutcomments and suggestions for beer and food.
                                Whether you're a beginner or a seasoned runner,
                                Urris' expertise and experience can help you improve your performance and reach new
                                personal bests. And best of it, he will make running more fun!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseThree">
                              Face 2 - Stats
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingThree">
                            <div class="accordion-body text-start">
                              Second face of Urris is his intrest in statistics. As a statistician in the insurance
                              industry, he is here to help. His expertise in data analysis and statistics can help you gain a deeper understanding of
                              your running stats, giving you the insights you need to improve your performance. Using the latest in statistical
                              techniques, Urris can help you analyze your data and create a customized training plan tailored to your specific goals.
                              Don't leave your running performance to chance, let Urris help you unlock your full potential.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div> -->
              </div>

            </div>
          </div>
          <div class="col-6">
            <!-- Masthead device mockup feature-->
            <div class="masthead-device-mockup">
              
             
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Quote/testimonial aside-->
    <aside class=" bg-gradient bg-light">
      <div class="container p-5">
        <div class="row gx-5 justify-content-center">
          <div class="col-xl-8">
            <div class="h2 fs-1 mb-4">A simple solution to a common running related problem!<br><br> Paying
              for a running coach</div>
            <!-- <img src="assets/img/tnw-logo.svg" alt="..." style="height: 3rem" /> -->
          </div>
        </div>
      </div>
    </aside>
    <!-- App features section-->
    <section id="features">
      <div class="container p-5">
        <div class="row gx-5 align-items-md-center flex-row-reverse">
          <div class="col-12 order-lg-1 mb-5 mb-lg-0">
            <h2 class="text-center">Features</h2>
            <div class="container-fluid px-5">
              <div class="row gx-5">
                <div class="col-md-6 mb-5 mb-md-0">
                  <!-- Feature item-->
                  <div class="">
                    <i class="bi-phone icon-feature text-gradient d-block mb-3"></i>
                    <h3 class="font-alt mb-1">THE SCORE</h3>
                    <p class="text-muted mb-0">Urris is giving every athlete a score. The Urrisscore. It tells you and the world how good you are as a runner. Ranging from 1-100</p>
                  </div>
                </div>
                <div class="col-md-6 mb-5">
                  <!-- Feature item-->
                  
                  <div class="">
                    <i class="bi-gift icon-feature text-gradient d-block mb-3"></i>
                    <h3 class="font-alt mb-1">Workout suggestions</h3>
                    <p class="text-muted mb-0">Tell Urris your goal and he will give you multiple suggestions for
                      workouts</p>
                  </div>
                </div>
                <div class="col-md-6 mb-5">
                  <!-- Feature item-->
                  <div class="">
                    <i class="bi-camera icon-feature text-gradient d-block mb-3"></i>
                    <h3 class="font-alt mb-1">Useful insights of your running</h3>
                    <p class="text-muted mb-0">Urris will sneak peak at your Strava sessions and provide pro tip from
                      there</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-5 mb-md-0">
                  <!-- Feature item-->
                  <div class="">
                    <i class="bi-gift icon-feature text-gradient d-block mb-3"></i>
                    <h3 class="font-alt mb-1">Free</h3>
                    <p class="text-muted mb-0">Urris does not charge any monthly fee. If you are based in sweden you can swish an amount for keeping webbased Urris going</p>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic features section-->
    <section class="bg-light">
      <div class="container p-5">
        <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
          <div class="col-12 col-lg-5">
            <h2 class="display-4 lh-1 mb-4">Enter a new age of running coach</h2>
            <p class="lead fw-normal text-muted mb-5 mb-lg-0 text-start">All of those running influencers are trying to make you
              feel special, but behind your back provides the exact same schedule for everyone. <br><br>Because of this
              Urris was created. He does the same thing, probably better and definitly cheaper. Best of it. Urris is
              fun.
              He will encourage you to run faster, he will provide you with insightful stats and he will even give you
              cooking advice. Or at least some drinking advice.
            </p>
          </div>
          <div class="col-sm-8 col-md-6">
            <div class="px-5 px-sm-0"><img class="img-fluid" src="avatar-cook.png" alt="..." /></div>
          </div>
        </div>
      </div>
    </section>
   


    <!-- Feedback Modal-->
    <div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-gradient-primary-to-secondary p-4">
            <h5 class="modal-title font-alt text-white" id="feedbackModalLabel">Send feedback</h5>
            <button class="btn-close btn-close-white" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body border-0 p-4">
            <!-- * * * * * * * * * * * * * * *-->
            <!-- * * SB Forms Contact Form * *-->
            <!-- * * * * * * * * * * * * * * *-->
            <!-- This form is pre-integrated with SB Forms.-->
            <!-- To make this form functional, sign up at-->
            <!-- https://startbootstrap.com/solution/contact-forms-->
            <!-- to get an API token!-->
            <form id="contactForm" data-sb-form-api-token="API_TOKEN">
              <!-- Name input-->
              <div class="form-floating mb-3">
                <input class="form-control" id="name" type="text" placeholder="Enter your name..."
                  data-sb-validations="required" />
                <label for="name">Full name</label>
                <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
              </div>
              <!-- Email address input-->
              <div class="form-floating mb-3">
                <input class="form-control" id="email" type="email" placeholder="name@example.com"
                  data-sb-validations="required,email" />
                <label for="email">Email address</label>
                <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
              </div>
              <!-- Phone number input-->
              <div class="form-floating mb-3">
                <input class="form-control" id="phone" type="tel" placeholder="(123) 456-7890"
                  data-sb-validations="required" />
                <label for="phone">Phone number</label>
                <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
              </div>
              <!-- Message input-->
              <div class="form-floating mb-3">
                <textarea class="form-control" id="message" type="text" placeholder="Enter your message here..."
                  style="height: 10rem" data-sb-validations="required"></textarea>
                <label for="message">Message</label>
                <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
              </div>
              <!-- Submit success message-->
              <!---->
              <!-- This is what your users will see when the form-->
              <!-- has successfully submitted-->
              <div class="d-none" id="submitSuccessMessage">
                <div class="text-center mb-3">
                  <div class="fw-bolder">Form submission successful!</div>
                  To activate this form, sign up at
                  <br />
                  <a
                    href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                </div>
              </div>
              <!-- Submit error message-->
              <!---->
              <!-- This is what your users will see when there is-->
              <!-- an error submitting the form-->
              <div class="d-none" id="submitErrorMessage">
                <div class="text-center text-danger mb-3">Error sending message!</div>
              </div>
              <!-- Submit Button-->
              <div class="d-grid"><button class="btn btn-primary rounded-pill btn-lg disabled" id="submitButton"
                  type="submit">Submit</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UrrisComment from './UrrisComment.vue';
export default {
  name: 'LandingPage',
  components: {
    UrrisComment
  },
  data: function() {
    return {
      showImage: false,
      urrisMessage: ''
    }
  },
  mounted() {
  },
  methods: {
    onVideoEnd() {
      this.showImage = true,
      setTimeout(() => {
        this.urrisMessage = "That was me doing a LT1 session. I am Urris, your new running coach";
      }, 100);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.sticky-element {
  position: relative;
}
.sticky {
  position: fixed;
  top: 5px;
  left: 0;
  width: 100%;
  z-index: 999;
}
.small {
  transform: scale(0.8);
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.fade-in-image {
  position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}
/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
