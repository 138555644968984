<template>
    <div>


        <div class="container-fluid">
            <div class="header px-2 pb-4">
                <UrrisComment :comment="activity.uhrisComment" ref="comment" :widthstyle="'60px'"></UrrisComment>

            </div>
            <div class="body">

                <div class="row mb-md-4 mb-2">
                    <div>
                        <ul class="nav nav-tabs flex">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#tab1">Analysis</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#tab2">Simliar activities</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#tab3">Up next</a>
                            </li>
                        </ul>
                    </div>


                </div>
                <div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab1">
                            <div class="col-12 col-md-6 row">
                                <h6>Intervals grouped by distance</h6>
                                <div class="container">

                                    <table class="table table-responsive table-bordered">
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>Distance</th>
                                            <th>Intervals (at this distance)</th>
                                            <th>Average time</th>
                                            <th>Rest between</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="group in activity.grouped_laps" :key="group.key">
                                            <td>{{ group[group.length-1].key }} m</td>
                                            <td>{{ group.length - 1}}</td>
                                            <td>{{ $formatTime(group[group.length-1].avg_time) }}</td>
                                            <td>{{ avg_rest_time }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h6>Totals</h6>
                                <div class="container">
                                    <table class="table table-responsive table-bordered">
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>Total interval distance</th>
                                            <th>Average pace on intervals</th>
                                            <th>Average heartrate during intervals</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ Math.round(activity.intervals?.length * activity.avg_distance_intervals) }} m</td>
                                            <td>{{ avg_pace_intervals }} min/km</td>   
                                            <td></td>                                      
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            </div>

                        </div>
                        <div class="tab-pane" id="tab2">
                            <div class="container">

                                <div class="col-12 col-md-6 row" v-if="activity.similar_activities?.length">
                               
                                <ActivityCard class="my-2 col-12 col-md-6"
                                v-for="sactivity in activity.similar_activities" :activity="sactivity"
                                :key="sactivity.id"></ActivityCard>
                            </div>
                        </div>
                        </div>
                        <div class="tab-pane" id="tab3">Tab 3 content</div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import UrrisComment from '@/components/UrrisComment';
import ActivityCard from '@/components/ActivityCard';
export default {

    watch: {
        '$route.params.id': function () {
            this.activity = {};
            this.loadActivityDetails();

        }
    },

    components: {
        UrrisComment,
        ActivityCard
    },
    computed: {
        activityId() {
            return parseInt(this.$route.params.id);
        },
        avg_interval_time(){
            return this.$formatTime(this.activity.avg_interval_time)
        },
        avg_rest_time(){
            return this.$formatTime(this.activity.avg_rest_time) 
        },
        avg_pace_intervals() {
           return this.$formatMpsToMpk(this.activity.avg_distance_intervals / this.activity.avg_interval_time)
        }
    },
    data() {
        return {
            activity: {}
        };
    },
    mounted() {

        this.loadActivityDetails();

    },

    methods: {

        loadActivityDetails() {
            axios
                .get(`api/activity/${this.activityId}`)
                .then(response => {
                    this.activity = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },


    }
};
</script>

<style scoped>
.activity-details {
    position: relative;
}

.activity-details img {
    height: auto;
}
</style>  