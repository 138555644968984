
export default {
    install: (app) => {
        app.config.globalProperties.$formatMpsToMpk = (metersPerSecond) => {

            const minutesPerKilometer = (1 / (metersPerSecond / 1000)) / 60;
            const minutes = Math.floor(minutesPerKilometer);
            const seconds = Math.floor((minutesPerKilometer - minutes) * 60);
            return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
        }
    }
}
