<template>

  <div>

        <ResponsiveLineChart v-if="fullResponse" :data="fullResponse" xAxisLabel="date"></ResponsiveLineChart>
   
  </div>

</template>

<script>
// import Chart from 'chart.js'
import axios from 'axios'
import ResponsiveLineChart from "@/components/ResponsiveLineChart.vue";

export default {
  name: 'DevelopmentOverTime',
  components: {
    ResponsiveLineChart,
  },
  data() {
    return {
      chart: null,
      datasets: [],
      labels: [],
      rectangleSet: false,
      fullResponse: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios.get('api/athletedashdevovertime')
        .then(response => {
          this.fullResponse = response.data;
          this.labels = response.data.map(datapoint => `${this.$formatDate(datapoint.start_date)} to ${this.$formatDate(datapoint.end_date)}`)
          this.datasets = [
            {
              label: 'Sum Distance',
              data: response.data.map(datapoint => datapoint.sum_distance),
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,

            },
            {
              label: 'Average Speed',
              data: response.data.map(datapoint => datapoint.avg_speed),
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,

            },
            {
              label: 'Race Results',
              data: response.data.map(datapoint => datapoint.race_count),
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1,

            }
          ]
          // this.renderChart()
        })
    },

    updateSelectedDataset(selectedDataset) {
      this.selectedDataset = selectedDataset;
      this.chart.data.datasets = this.selectedDataset ? [this.datasets.find(dataset => dataset.label === this.selectedDataset)] : this.datasets;
      this.chart.update()
    }
    
  }
}
</script>
<style scoped>
.chartWrapper {
  position: relative;
}

.chartWrapper>canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 600px;
  overflow-x: scroll;
}
</style>