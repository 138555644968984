<template>
  <div ref="chartContainer" class="chart-container">
    <!-- Date selector controls -->
    <div class="date-selectors mb-3">
      <div class="d-flex gap-1 align-items-end">
        <div class="mb-2">
          <label for="startDate" class="form-label">Start Date:</label>
          <input type="date" id="startDate" class="form-control form-control-sm" v-max="Date.now()" v-model="customStartDate" @change="updateCustomDateRange" />
        </div>
        <div class="mb-2">
          <label for="endDate" class="form-label">End Date:</label>
          <input type="date" id="endDate" class="form-control form-control-sm" v-max="Date.now()" v-model="customEndDate" @change="updateCustomDateRange" />
        </div>
        <div class="mb-2">
          <button class="btn btn-sm btn-outline-secondary" @click="resetDates">Reset Dates</button>
        </div>
      </div>
    </div>

    <!-- Controls for toggling lines -->
    <div class="d-flex justify-content-center mb-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input avg-speed" type="checkbox" id="showAvgSpeed" v-model="showAvgSpeed" />
        <label class="form-check-label avg-speed" for="showAvgSpeed">Show Avg Speed</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input sum-distance" type="checkbox" id="showSumDistance" v-model="showSumDistance" />
        <label class="form-check-label sum-distance" for="showSumDistance">Show Sum Distance</label>
      </div>
    </div>

    <!-- SVG container for the graph -->
    <svg ref="svg" class="w-100"></svg>

    <!-- Data details pop-up -->
    <div v-if="selectedData" class="data-details-box">
      <p class="mb-1"><strong>{{ new Date(selectedData.start_date).toLocaleDateString('sv-SE') }} - {{ new Date(selectedData.end_date).toLocaleDateString('sv-SE') }}</strong></p>
      <p class="mb-0"><strong>Avg Speed:</strong> {{ $secondsToMinutesPerKm(selectedData.avg_speed) }}</p>
      <p class="mb-0"><strong>Sum Distance:</strong> {{ selectedData.sum_distance }} km</p>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAvgSpeed: true,
      showSumDistance: true,
      customStartDate: null,
      customEndDate: null,
      filteredData: this.data,
      xScale: null,
      margin: { top: 20, right: 30, bottom: 50, left: 30 },
      selectedData: null, // For displaying the hovered data point
      verticalLine: null,  // To store the vertical line element
    };
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    updateCustomDateRange() {
      const start = new Date(this.customStartDate);
      const end = new Date(this.customEndDate);

      if (this.customStartDate && this.customEndDate) {
        this.filteredData = this.data.filter((d) => {
          const date = new Date(d.start_date);
          return date >= start && date <= end;
        });
        this.drawChart();
      }
    },
    resetDates() {
      this.customStartDate = null;
      this.customEndDate = null;
      this.filteredData = this.data;
      this.drawChart();
    },
    drawChart() {
      const svg = d3.select(this.$refs.svg);
      svg.selectAll("*").remove();

      const margin = this.margin;
      const width = parseInt(d3.select(this.$refs.chartContainer).style('width')) - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;

      const g = svg.attr("width", width + margin.left + margin.right)
                   .attr("height", height + margin.top + margin.bottom)
                   .append("g")
                   .attr("transform", `translate(${margin.left},${margin.top})`);

      this.xScale = d3.scaleTime()
        .domain(d3.extent(this.filteredData, d => new Date(d.start_date)))
        .range([0, width]);

      const yScaleAvgSpeed = d3.scaleLinear() // Invert the Y-axis
        .domain([150, 450])
        .range([0, height]);

      const yScaleSumDistance = d3.scaleLinear()
        .domain([0, d3.max(this.filteredData, d => d.sum_distance)]).nice()
        .range([height, 0]);

      const xAxis = d3.axisBottom(this.xScale);

      // Adjust tick values to your desired set
      const tickValues = [150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450];

      const yAxisLeft = d3.axisLeft(yScaleAvgSpeed)
        .tickValues(tickValues)
        .tickFormat(d => this.$secondsToMinutesPerKm(d));

      const yAxisRight = d3.axisRight(yScaleSumDistance);

      g.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(xAxis);

      g.append("g").call(yAxisLeft)
      .attr("stroke", "blue");

      g.append("g")
      .attr("stroke", "red")
        .attr("transform", `translate(${width},0)`)
        .call(yAxisRight);

      const lineAvgSpeed = d3.line()
        .x(d => this.xScale(new Date(d.start_date)))
        .y(d => yScaleAvgSpeed(d.avg_speed)); // Inverting the line

      const lineSumDistance = d3.line()
        .x(d => this.xScale(new Date(d.start_date)))
        .y(d => yScaleSumDistance(d.sum_distance));

      if (this.showAvgSpeed) {
        g.append("path")
          .datum(this.filteredData)
          .attr("fill", "none")
          .attr("stroke", "blue")
          .attr("stroke-width", 2)
          .attr("d", lineAvgSpeed);
      }

      if (this.showSumDistance) {
        g.append("path")
          .datum(this.filteredData)
          .attr("fill", "none")
          .attr("stroke", "red")
          .attr("stroke-width", 2)
          .attr("d", lineSumDistance);
      }

      // Create a vertical line that follows the mouse
      this.verticalLine = g.append("line")
        .attr("class", "vertical-line")
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .style("opacity", 0);

      // Attach mouse event listeners
      svg.on('mousemove', (event) => this.handleMouseMove(event, width, height, yScaleAvgSpeed));
      svg.on('touchmove', (event) => this.handleMouseMove(event, width, height, yScaleAvgSpeed));
    },
    handleMouseMove(event) {
      const [mouseX] = d3.pointer(event);
      const xDate = this.xScale.invert(mouseX - this.margin.left);

      // Find the closest data point to the current mouse position
      const closestData = this.filteredData.reduce((prev, curr) => {
        return (Math.abs(new Date(curr.start_date) - xDate) < Math.abs(new Date(prev.start_date) - xDate)) ? curr : prev;
      });

      // Update the position of the vertical line
      this.verticalLine
        .attr("x1", this.xScale(new Date(closestData.start_date)))
        .attr("x2", this.xScale(new Date(closestData.start_date)))
        .style("opacity", 1);

      // Display the selected data details
      this.selectedData = closestData;
    },
  },
  watch: {
    showAvgSpeed() {
      this.drawChart();
    },
    showSumDistance() {
      this.drawChart();
    },
  },
};
</script>

<style scoped>
svg {
  background-color: white;
}

.data-details-box {
  position: relative;
  top: -10px; /* Position below the graph */
  max-width: 50%;
  border: 1px solid black;
  padding: 10px;
  margin: auto;
  border-radius: 5px;
  font-size: 12px; /* Smaller text */
}

.vertical-line {
  pointer-events: none;
}
.avg-speed {
  color: blue;
}

.sum-distance {
  color: red;
}
.form-check-input.sum-distance:checked{
  background-color: red;
  border-color: red;
}
.form-check-input.avg-speed:checked{
  background-color: blue;
  border-color: blue;
}

</style>
