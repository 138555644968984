<template>
    <div>
        <div class="p-1">

            <UrrisComment :widthstyle="'150px'" :comment="'The first time you enter the world of Urris I have to do some research. It can take a minute or two. You can comeback or just wait. You see the progress of my analysis below'"></UrrisComment>
        </div>
      <div class="mt-3 p-3">

          <div v-if="loading">
              <p class="mb-0">Fetching Activities...</p>
              <progress :value="progress" :max="100"></progress>
            </div>
            <div v-else>
                <p>Fetch Complete</p>
                <p><router-link to="/user">Go to dashboard</router-link></p>
            </div>
            <p>{{ fetchedActivities }} / {{ totalActivities }}</p>
            <p>If you have activities not displayed to everyone in Strava that will affect the total number displayed here. I will however analyse all your activties</p>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
import UrrisComment from './UrrisComment.vue';
  export default {
    components: {
      UrrisComment
    },
    data() {
      return {
        fetchedActivities: 0,  // Number of activities fetched
        totalActivities: 100,  // Total number of activities (set based on cookie or initial value)
        progress: 0,           // Progress bar value
        loading: true,
        intervalId: null          // Show loading status
      };
    },
    methods: {
      fetchActivityData() {
        // Get the total activities from the cookie (if available)
        const totalActivitiesCookie = this.$cookies.get('total_run_count');
        
        if (totalActivitiesCookie) {
          this.totalActivities = parseInt(totalActivitiesCookie);
        }
  
        axios
          .get('/api/fetchedactivities')
          .then((response) => {
            this.fetchedActivities = response.data;
           
  
            // Calculate progress percentage
            this.progress = (this.fetchedActivities / this.totalActivities) * 100;
          })
          .catch((error) => {
            console.error('Error fetching activity data:', error);
          });
      }
    },
    mounted() {
      // Call the fetchActivityData method every 3 seconds
      this.intervalId = setInterval(() => {
        this.fetchActivityData();
      }, 3000);
    },
    watch: {
      fetchedActivities(newVal) {
        if (newVal >= this.totalActivities) {
          this.loading = false;  // Stop loading when all activities are fetched
          clearInterval(this.intervalId);  // Stop the interval
        }
      }
    }
  };
  </script>
  
  <style scoped>
  progress {
    width: 100%;
    height: 30px;
    margin-top: 10px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  p {
    font-size: 18px;
  }
  </style>
  