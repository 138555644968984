<template>
    <!-- Footer-->
    <footer class=" bg-primary text-center py-5 mt-auto">
        <div class="container px-5">
            <div class="text-white-50 small">
                <div class="mb-2">&copy; Urris 2023. All Rights Reserved.</div>
                <a href="#!">Privacy</a>
                <span class="mx-1">&middot;</span>
                <a href="#!">Terms</a>
                <span class="mx-1">&middot;</span>
                <a href="#!">FAQ</a>
            </div>
        </div>
    </footer>

</template>
<script>
export default {
    name: 'Footer'
}
</script>
<style scoped>
    footer a {
        color: aliceblue;
    }
</style>