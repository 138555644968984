// function formatTime(seconds) {
//     let hours = Math.floor(seconds / 3600);
//     let minutes = Math.floor((seconds % 3600) / 60);
//     let remainingSeconds = seconds % 60;
//     let formattedTime = '';

//     if (hours > 0) {
//         formattedTime = hours + ':' + pad(minutes) + ':' + pad(remainingSeconds);
//     } else {
//         formattedTime = minutes + ':' + pad(remainingSeconds);
//     }

//     return formattedTime;
// }

function pad(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

export default {
    install: (app) => {
        app.config.globalProperties.$secondsToMinutesPerKm = (seconds, smallestEntity = 'seconds') => {
            const totalMinutes = seconds / 60;

            const minutesPerKm = totalMinutes;
            const minutes = Math.floor(minutesPerKm);
            const remainingSeconds = minutesPerKm - minutes;

            let formattedTime = '';
            if (smallestEntity === 'hundredths') {
                formattedTime = `${minutes}:${pad((remainingSeconds * 60).toFixed(2))}`;
            } else {
                formattedTime = `${minutes}:${pad((remainingSeconds * 60).toFixed(0))}`;
            }
            return formattedTime;
        }

    }
}
