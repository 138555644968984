<template>
    <UrrisComment :comment="comment"  :widthstyle="'60px'"/>
    <div class="container mt-5">
      <h2>Weekly Workouts</h2>
      
      <!-- Dropdowns for Month and Year -->
      <div class="d-flex justify-content-start mb-3">
        <select v-model="selectedMonth" class="form-select me-2" @change="scrollToSelection">
          <option value="">Select Month</option>
          <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
        </select>
        
        <select v-model="selectedYear" class="form-select" @change="scrollToSelection">
          <option value="">Select Year</option>
          <option v-for="year in uniqueYears" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>
      
      <!-- Table to display weekly workouts -->
       <div class=" table-responsive">
      <table class="table table-striped table-bordered" style="max-width: 100vw;" ref="workoutTable">
        <thead>
          <tr>
            <th scope="col">Week</th>
            <th scope="col">Year</th>
            <th scope="col">Monday</th>
            <th scope="col">Wednesday</th>
            <th scope="col">Saturday</th>
          </tr>
        </thead>
        <tbody>
          <!-- Each row is assigned an ID for autoscrolling -->
          <tr 
            v-for="(workout, index) in weeklyWorkouts" 
            :key="index" 
            :id="'row-' + workout.year + '-' + getMonthFromDate(workout.sunday_week_date)"
          >
            <td>{{ workout.year }}</td>
            <td>{{ workout.week }}</td>
            <td>{{ workout.monday_session }}</td>
            <td>{{ workout.wednesday_session }}</td>
            <td>{{ workout.saturday_session }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import UrrisComment from './UrrisComment.vue';
//   import { ref, onMounted, computed } from 'vue'
  
  export default {
    name: 'WeeklyWorkouts',
    components: {
      UrrisComment
    },
    data() {
      return {
        weeklyWorkouts: [],    // Stores workout data
        selectedMonth: '',     // Selected month from dropdown
        selectedYear: '',      // Selected year from dropdown
        months: [              // Month names for the dropdown
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ],
        comment: ''
      }
    },
    computed: {
      // Computed property to get unique years from the fetched data
      uniqueYears() {
        return [...new Set(this.weeklyWorkouts.map(workout => workout.year))]
      }
      
    },
    mounted() {
      axios.get('api/weeklyworkouts')  // Replace with your actual API endpoint
        .then(response => {
        this.comment= 'You are NOT unique, if someone sells you personalized training schedules and you dont aim to run a sub 29min (W 33 min) 10K. Its a scam. '
         +'Do the same thing as every good runner has done before you. RUN! These are some mainstream workouts to give you some inspiration.'
          this.weeklyWorkouts = response.data.map(workout => ({
            ...workout,
            year: this.extractYear(workout.sunday_week_date)
          }))
        })
        .catch(error => {
          console.error("Error fetching workouts:", error)
        })
    },
    methods: {
      // Function to extract year from the date string
      extractYear(dateText) {
        const dateObj = new Date(dateText)
        return dateObj.getFullYear()
      },
      // Function to extract month number (1-12) from the date string
      getMonthFromDate(dateText) {
        const dateObj = new Date(dateText)
        return dateObj.getMonth() + 1
      },
      // Function to scroll to the selected month and year
      scrollToSelection() {
        if (this.selectedMonth && this.selectedYear) {
          const rowId = `row-${this.selectedYear}-${this.selectedMonth}`
          const rowElement = document.getElementById(rowId)
          if (rowElement) {
            rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
          } else {
            console.warn('No matching row found for selected month and year')
          }
        }
      }
    }
  }
  </script>
  
  <style scoped>
  /* Optional custom styles for table layout */
  table {
    margin-top: 20px;
  }
  
  th, td {
    text-align: center;
  }
  .table-responsive {
  max-width: 95vw;
}
  </style>
  