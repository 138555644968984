<template>
    <div class="uhircomment-container" :class="fullsize ? 'fullsize': ''">
        <div :style="{ 'width': widthstyle }">
            <img :src="fullsize ? '/avatar-jumpsuit.png' :  '/avatar-face.png'" alt="Running coach" class="mr-3">
        </div>
        <div class="speech-bubble">
            {{ typedComment }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'UrrisComment',
    props: {
        id: String,
        comment: String,
        widthstyle: Number,
        fullsize: Boolean
    },
    data() {
        return {
            typedComment: ""
        }
    },
    watch: {
        'comment': function () {
            if(this.comment){

                this.typedComment = ""
                this.typeComment()
            }
        }
    },
    mounted() {
    const currentPath = window.location.pathname;
    if (currentPath === "/newuser") {
      this.typeComment();
    }
},
    methods: {
        typeComment() {
            let i = 0;
            const type = () => {
                if (i < this.comment?.length) {
                    this.typedComment += this.comment.charAt(i);
                    i++;
                    setTimeout(type, Math.floor(Math.random() * 100));
                }
            };
            type();
        },
    }
}
</script>
<style scoped>
.uhircomment-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.uhircomment-container.fullsize {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.uhircomment-container img {
    width: 100%;
    min-width: 60px;
}
.speech-bubble {
  position: relative;
  background: #fff;
  border-radius: .4em;
  padding: 1em;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.fullsize .speech-bubble {
    margin-top: 20px;
}

</style>